import React, { useState } from "react";
import { VideoContent } from "./GridItems/ProjectItems";
import ReactPlayer from "react-player";
import PlayButton from "./Icons/Icons";

const Video = ({ color, image, url, alt }) => {
  const [playing, setPlaying] = useState(false);
  return (
    <VideoContent color={color} play={playing} onClick={() => setPlaying(true)}>
      <div className="cover"></div>
      <img alt={alt} src={image} />
      <div className="lightbox" onClick={() => setPlaying(false)}>
        <ReactPlayer
          onPause={() => setPlaying(false)}
          onEnded={() => setPlaying(false)}
          url={url}
          height="100%"
          controls={true}
          width="100%"
          playing={playing}
          className="vid-player"
        />
      </div>
      <PlayButton />
    </VideoContent>
  );
};

export default Video;
