import React, { Component } from "react";
import { Switch, Route, withRouter } from "react-router-dom";
import ReactGA from "react-ga";

import HomePage from "./Pages/Home";

import "./App.css";
import InfoPage from "./Pages/Info";
import BlogPage from "./Pages/Blog";
import ProjectPage from "./Pages/Project";
import Colors from "./Utils/Colors";
import NotFound from "./Pages/404";

const colors = new Colors();

function initializeReactGA() {
  ReactGA.initialize("UA-179633386-1");
  ReactGA.pageview("/");
}

class App extends Component {
  constructor(props) {
    super(props);
    this.state = {
      categories: {
        work: {
          square: 122,
          name: "Work",
          width: 3,
          height: 1,
          color: colors.palette[0],
        },
        vr: {
          square: 126,
          name: "VR",
          width: 2,
          height: 1,
          color: colors.palette[3],
        },
        apps: {
          square: 129,
          name: "Apps",
          width: 2,
          height: 1,
          color: colors.palette[2],
        },
        web: {
          square: 168,
          name: "Web",
          width: 2,
          height: 1,
          color: colors.palette[6],
        },
        ar: {
          square: 171,
          name: "AR",
          width: 2,
          height: 1,
          color: colors.palette[7],
        },
        threed: {
          square: 174,
          name: "3D",
          width: 3,
          height: 1,
          color: colors.palette[4],
        },
      },
    };
  }

  componentDidMount() {
    initializeReactGA();
    // First we get the viewport height and we multiple it by 1% to get a value for a vh unit
    let vh = window.innerHeight * 0.01;
    // Then we set the value in the --vh custom property to the root of the document
    document.documentElement.style.setProperty("--vh", `${vh}px`);

    // We listen to the resize event
    window.addEventListener("resize", () => {
      // We execute the same script as before
      let vh = window.innerHeight * 0.01;
      document.documentElement.style.setProperty("--vh", `${vh}px`);
    });
  }

  render() {
    const { categories } = this.state;

    return (
      <div className="App">
        <Switch>
          <Route
            exact
            path="/"
            render={(props) => <HomePage {...props} categories={categories} />}
          />
          <Route
            exact
            path="/about"
            render={(props) => <InfoPage {...props} />}
          />
          <Route
            exact
            path="/blog"
            render={(props) => <BlogPage {...props} />}
          />
          <Route
            exact
            path="/project/:name"
            render={(props) => (
              <ProjectPage {...props} categories={categories} />
            )}
          />
          <Route component={NotFound} />
        </Switch>
      </div>
    );
  }
}

export default withRouter(App);
