import Colors from "./Colors";

let colors = new Colors();

export const setTitle = () => {
  let square = document.getElementById("square-0");

  square.classList.add("title");
};

export const setMenuButtons = (squareItems, pathname) => {
  let aboutButton = document.getElementById(`square-${squareItems[0] - 2}`);
  let blogButton = document.getElementById(`square-${squareItems[0] * 2 - 2}`);

  blogButton.classList.add("blog-button");
  aboutButton.classList.add("about-button");

  createCategory(
    aboutButton,
    1,
    2,
    "About",
    "",
    colors.palette[1],
    "/about",
    pathname
  );
  createCategory(
    blogButton,
    1,
    2,
    "Blog",
    "",
    colors.palette[5],
    "/blog",
    pathname
  );
};

export const createCategory = (
  el,
  height,
  width,
  title,
  order,
  color,
  link,
  activePath
) => {
  el.classList.add("category");

  if (link) {
    el.setAttribute("link", link);
  }

  el.setAttribute("height", height);
  el.setAttribute("width", width);
  el.setAttribute("title", title);
  el.setAttribute("order", order);
  el.setAttribute("color", color);
  el.setAttribute("active-path", activePath);
};

export const getImageOrientation = (width, height) => {
  if (width > height) {
    return "landscape";
  } else {
    return "portrait";
  }
};

export const removeCategory = (el) => {
  el.classList.remove("category");
  el.removeAttribute("height");
  el.removeAttribute("width");
  el.removeAttribute("title");
  el.removeAttribute("order");
  el.removeAttribute("color");
};

export function sideScroll(element, direction, speed, distance, step) {
  let scrollAmount = 0;
  var slideTimer = setInterval(function () {
    if (direction === "previous") {
      element.scrollLeft -= step;
    } else {
      element.scrollLeft += step;
    }
    scrollAmount += step;
    if (scrollAmount >= distance) {
      window.clearInterval(slideTimer);
    }
  }, speed);
}

let timerId;

export const throttle = (func, delay) => {
  // If setTimeout is already scheduled, no need to do anything
  if (timerId) {
    return;
  }

  // Schedule a setTimeout after delay seconds
  timerId = setTimeout(function () {
    func();

    // Once setTimeout function execution is finished, timerId = undefined so that in <br>
    // the next scroll event function execution can be scheduled by the setTimeout
    timerId = undefined;
  }, delay);
};
