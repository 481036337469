import React from "react";
import {
  RelatedPostContainer,
  RelatedPostImage,
  RelatedPostName,
} from "./GridItems/ProjectItems";
import { Link } from "react-router-dom";
import { getImageOrientation } from "../Utils/GridControl";

const RelatedPost = ({ post, color }) => {
  console.log(post.fields.previewImage.fields);
  return (
    <Link to={post.fields.slug}>
      <RelatedPostContainer>
        <RelatedPostImage
          orientation={getImageOrientation(
            post.fields.previewImage.fields.file.details.image.width,
            post.fields.previewImage.fields.file.details.image.height
          )}
        >
          <img
            alt={post.fields.previewImage.fields.title}
            src={post.fields.previewImage.fields.file.url + "?q=20"}
          />
        </RelatedPostImage>
        <RelatedPostName color={color}>
          <p>{post.fields.name}</p>
        </RelatedPostName>
      </RelatedPostContainer>
    </Link>
  );
};

export default RelatedPost;
