import React from "react";

export function PlayButton() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="80"
      height="80"
      viewBox="0 0 142 142"
    >
      <g fill="none" fillRule="evenodd" stroke="none" strokeWidth="1">
        <g
          fill="#F7F6F4"
          stroke="#000"
          strokeWidth="2"
          transform="translate(1 2)"
        >
          <path d="M69 0L138 138 0 138z" transform="rotate(90 69 69)"></path>
        </g>
      </g>
    </svg>
  );
}

export default PlayButton;