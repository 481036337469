import React, { useState, useRef, useEffect } from "react";
import { documentToReactComponents } from "@contentful/rich-text-react-renderer";
import { INLINES } from "@contentful/rich-text-types";
import {
  Content,
  ContentWrapper,
  ScrollBar,
  ProjectImage,
  MobileImages,
  MobileProjectContent,
  ProjectPageTitle,
  ProjectDescription,
  MoreInfoText,
} from "./GridItems/ProjectItems";
import Video from "./Video";
import {
  getImageOrientation,
  sideScroll,
  throttle,
} from "../Utils/GridControl";
import { ArrowButton, CloseButton } from "./GridItems/Buttons/Buttons.styles";
import RelatedPost from "../Components/RelatedPost";

const ProjectContent = ({ color, project, descriptionOpen }) => {
  const [progress, setProgress] = useState(0);
  const [width, setWidth] = useState(0);
  const item = useRef();

  useEffect(() => {
    const ref = item.current;

    if (width === 0) {
      calculateWidth();
    }

    setProgress(((ref.scrollLeft + ref.offsetWidth) * 100) / ref.scrollWidth);

    window.addEventListener("wheel", throttledScroll);

    ref.addEventListener("scroll", updateBar);

    return () => {
      window.removeEventListener("wheel", throttledScroll);

      ref.removeEventListener("scroll", updateBar);
    };
  }, [item, width]);

  const throttledScroll = (e) => {
    throttle(() => scroll(e), 30);
  };

  const updateBar = () => {
    setProgress(
      ((item.current.scrollLeft + item.current.offsetWidth) * 100) /
        item.current.scrollWidth
    );
  };

  const scroll = (e) => {
    if (!item.current) return;
    if (e.deltaY > 0) item.current.scrollLeft += 65;
    else item.current.scrollLeft -= 65;
  };

  const scrollGallery = (direction) => {
    sideScroll(item.current, direction, 30, 844, 65);
  };

  const calculateWidth = () => {
    let calculatedWidth = width;
    project.projectImages.forEach((image, i) => {
      if (i === 0 && project.videoUrl) {
        calculatedWidth = calculatedWidth + 779;
      } else {
        calculatedWidth = calculatedWidth + 129;
      }

      let elWidth =
        getImageOrientation(
          image.fields.file.details.image.width,
          image.fields.file.details.image.height
        ) === "landscape"
          ? 779
          : 388;

      calculatedWidth = calculatedWidth + elWidth;
    });

    setWidth(calculatedWidth);
  };

  const options = {
    renderNode: {
      [INLINES.ENTRY_HYPERLINK]: (node, children) => {
        // If you are using contenful.js sdk, the referenced entry is resolved
        // automatically and is available at `node.data.target`.
        const referencedEntry = node.data.target;

        // console.log("Referenced Entry", referencedEntry);

        return (
          <a href={`/project/${referencedEntry.fields.slug}`}>{children}</a>
        );
      },
    },
  };

  return (
    <>
      <ProjectPageTitle color={color}>
        <h1>{project.name}</h1>
        {project?.skills?.length > 0 && (
          <p>
            {project.skills.map((skill, i) =>
              project.skills.length === i + 1 ? `${skill}. ` : `${skill}, `
            )}
          </p>
        )}
        <p>{project.date ? new Date(project.date).getFullYear() : "Present"}</p>
      </ProjectPageTitle>
      <ProjectDescription color={color} open={descriptionOpen}>
        <h3>{project.whyLine}</h3>
        {documentToReactComponents(project.projectDescription, options)}
        <div style={{ display: "flex", width: "100%" }}>
          {project.relatedPosts &&
            project.relatedPosts.map((post) => (
              <RelatedPost
                post={post}
                key={post.fields.previewImage.fields.title}
              />
            ))}
        </div>
      </ProjectDescription>
      <MoreInfoText>
        <h3>scroll or click arrows for more</h3>
      </MoreInfoText>
      <Content color={color} ref={item}>
        <ContentWrapper width={width}>
          {project.videoUrl ? (
            <Video
              alt={project.projectImages[0].fields.title}
              image={project.projectImages[0].fields.file.url}
              url={project.videoUrl}
            />
          ) : null}
          {project.projectImages.map((image, i) => {
            if (i === 0 && project.videoUrl) return null;
            return (
              <ProjectImage
                key={`image_${i}_${image.fields.title}`}
                orientation={getImageOrientation(
                  image.fields.file.details.image.width,
                  image.fields.file.details.image.height
                )}
              >
                <img alt={image.fields.title} src={image.fields.file.url} />
              </ProjectImage>
            );
          })}
        </ContentWrapper>
      </Content>
      <MobileProjectContent>
        <ProjectPageTitle color={color}>
          <h1>{project.name}</h1>
          {project?.skills?.length > 0 && (
            <p>
              {project.skills.map((skill, i) =>
                project.skills.length === i + 1 ? `${skill}. ` : `${skill}, `
              )}
            </p>
          )}
          <p>{new Date(project.date).getFullYear()}</p>
        </ProjectPageTitle>
        <ProjectDescription color={color} open={descriptionOpen}>
          <h3>{project.whyLine}</h3>
          {documentToReactComponents(project.projectDescription, options)}
          <div
            style={{ display: "flex", flexDirection: "column", width: "100%" }}
          >
            {project.relatedPosts &&
              project.relatedPosts.map((post) => (
                <RelatedPost
                  post={post}
                  key={post.fields.previewImage.fields.title}
                />
              ))}
          </div>
        </ProjectDescription>
        <MobileImages>
          {project.videoUrl ? (
            <Video
              alt={project.projectImages[0].fields.title}
              image={project.projectImages[0].fields.file.url}
              url={project.videoUrl}
            />
          ) : null}
          {project.projectImages.map((image, i) => {
            return (
              <ProjectImage
                key={`image_${i}_${image.fields.title}`}
                orientation={getImageOrientation(
                  image.fields.file.details.image.width,
                  image.fields.file.details.image.height
                )}
              >
                <img alt={image.fields.title} src={image.fields.file.url} />
              </ProjectImage>
            );
          })}
        </MobileImages>
      </MobileProjectContent>
      <ScrollBar color={color} progress={progress} />
      <CloseButton color={color} action={() => window.history.back()} />
      <ArrowButton
        type="next"
        color={color}
        action={() => scrollGallery("next")}
      />
      <ArrowButton color={color} action={() => scrollGallery("previous")} />
    </>
  );
};

export default ProjectContent;
