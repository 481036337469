const originalSizes = {
    width: 1496,
    height: 846,
    squareItems: [23, 13]
}

export const GridSizes = {
    width: {
        1920: {
            width: originalSizes.width,
            height: originalSizes.height,
            squareItems: originalSizes.squareItems[0]
        },
        1530: {
            width: originalSizes.width - 130,
            height: originalSizes.height,
            squareItems: originalSizes.squareItems[0] - 2
        },
        1390: {
            width: originalSizes.width - (130 * 2),
            height: originalSizes.height,
            squareItems: originalSizes.squareItems[0] - 4
        },
        1270: {
            width: originalSizes.width - (130 * 3),
            height: originalSizes.height,
            squareItems: originalSizes.squareItems[0] - 6
        },
        1140: {
            width: originalSizes.width - (130 * 4),
            height: originalSizes.height,
            squareItems: originalSizes.squareItems[0] - 8
        },
        1024: {
            width: originalSizes.width - (30 * 5),
            height: originalSizes.height,
            squareItems: originalSizes.squareItems[0] + 3
        },
        570: {
            width: originalSizes.width - (130 * 9),
            height: originalSizes.height,
            squareItems: originalSizes.squareItems[0] - 9
        }
    },
    height: {
        900: {
            width: originalSizes.width,
            height: originalSizes.height,
            squareItems: originalSizes.squareItems[1]
        },
        853: {
            width: originalSizes.width,
            height: originalSizes.height- (130),
            squareItems: originalSizes.squareItems[1] - 2
        },
    }
}