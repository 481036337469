import React, { useState } from "react";
import styled from "styled-components";
import { useSpring, animated } from "react-spring";

import pat3 from "../assets/Group 3.png";
import Colors from "../Utils/Colors";

const WindowContainer = styled(animated.div)`
  position: absolute;
  overflow-y: hidden;
  right: 0;
  bottom: 0;
  width: 260px;
  height: 260px;
  background-color: ${new Colors().background};
  display: flex;
  flex-direction: column;
  text-align: left;
  color: white;
  border-width: 1px;
  border-style: solid;
  border-color: ${({ open }) => (open ? "black" : "transparent")};
  transform: translate(-5px, -5px);

  transition: border-color 0.4s ${({ open }) => (open ? 0 : 1)}s;

  & img {
    height: 100%;
    opacity: ${({ open }) => (open ? 1 : 0)};

    transition: opacity 0.1s ${({ open }) => (open ? 1.4 : 0)}s;
  }

  & > div {
    opacity: ${({ open }) => (open ? 1 : 0)};

    transition: opacity 0.4s ${({ open }) => (open ? 0 : 0)}s;
  }

  @media (max-width: 1024px) {
    width: 100%;
    transform: translate(0px, 0px) !important;
    padding: 20px;

    & h3 {
      margin-top: 0px;
    }
  }
`;

const WindowTop = styled.div`
  position: absolute;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  right: 0;
  top: 0;
  width: 100%;
  height: 30px;
  border-bottom: 1px solid black;
  margin: 0;
  padding: 0;
  background-color: ${new Colors().background};
  color: black;
  padding: 0px 10px;
  cursor: pointer;

  &::before {
    content: "-";
    position: absolute;
    left: 0;
    top: 0;
    width: 30px;
    height: 30px;
    border-right: 1px solid black;
    color: black;
    display: flex;
    justify-content: center;
    align-items: center;
  }
`;

const WindowBackground = styled(animated.div)`
  position: absolute;
  left: ${({ position }) => (position.left ? position.left : "auto")};
  right: ${({ position }) => (position.right ? position.right : "auto")};
  top: ${({ position }) => (position.top ? position.top : "auto")};
  bottom: ${({ position }) => (position.bottom ? position.bottom : "auto")};
  z-index: 20;
  width: 259px;
  height: 259px;
  background: url("${pat3}") black center;
  opacity: ${({ open }) => (open ? 1 : 0)};
  pointer-events: ${({ open }) => (open ? "all" : "none")};

  transition: opacity 0.4s;

  @media (max-width: 1024px) {
    display: none;
  }
`;

const IconSquare = styled(animated.div)`
  position: absolute;
  display: ${({ hide }) => (hide ? "none" : "flex")};
  justify-content: center;
  align-items: center;
  z-index: 20;
  left: ${({ position }) => (position.left ? position.left : "auto")};
  right: ${({ position }) => (position.right ? position.right : "auto")};
  top: ${({ position }) => (position.top ? position.top : "auto")};
  bottom: ${({ position }) => (position.bottom ? position.bottom : "auto")};
  z-index: 21;
  width: 64px;
  height: 64px;
  /* background: url("${pat3}") black center; */
  cursor: pointer;
  opacity: ${({ open }) => (open ? 0 : 1)};

  transition: opacity 0.4s;

  & img {
    width: 33px;
  }

  & span {
    font-size: 10px;
  }

  @media (max-width: 1024px) {
    display: none;
  }
`;

const Window = ({ hide, headline, image, icon, letters, number, position }) => {
  const [open, setOpen] = useState(false);
  const bg = useSpring({
    bottom: `${open && !hide ? 0 : -194}px`,
  });

  const pop = useSpring({
    transform: `translate(${open && !hide ? 5 : 0}px, ${
      open && !hide ? -5 : 0
    }px)`,
    delay: open && !hide ? 1000 : 0,
  });

  return (
    <>
      <IconSquare
        position={position}
        hide={hide}
        open={open && !hide}
        onMouseOver={() => setOpen(true)}
      >
        {icon && <img alt="icon" src={icon} />}
        {!icon && letters && (
          <div>
            <h3>
              {letters}
              <span>{number}</span>
            </h3>
          </div>
        )}
      </IconSquare>
      <WindowBackground position={position} style={bg} open={open && !hide}>
        <WindowContainer style={pop} open={open && !hide}>
          <WindowTop onClick={() => setOpen(false)}>
            <p>{headline}</p>
          </WindowTop>
          <img alt="test" src={image + "?q=50"} />
        </WindowContainer>
      </WindowBackground>
    </>
  );
};

export default Window;
