import React, { Component } from 'react';
import styled from 'styled-components/macro';
import GridSquare from './GridSquare';
import Colors from '../Utils/Colors';
import { GridSizes } from '../Utils/gridSizes';

const colors = new Colors();

const GridContainer = styled.div`
    width: ${({ width }) => width}px;
    height: ${({ height }) => height}px;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    position: relative;
    overflow: ${({ overflow }) => overflow ? 'hidden' : 'initial'};
    border-bottom: 1px solid black;
    border-right: 1px solid black;
    transform: scale(${({ scale }) => scale || 1});

    will-change: transform;

    @media (max-width: 1024px) {
        width: 661px;
        height: 95%;
        min-height: auto;
        overflow-y: scroll;
        overflow-x: hidden;
    }

    @media (max-width: 600px) {
        width: 331px;
        height: 96%;
        min-height: auto;
        overflow-y: scroll;
        overflow-x: hidden;
    }
`

class Grid extends Component {
    constructor(props) {
        super(props);
        this.state = {
            squares: 299,
            height: 846,
            width: 1496,
            squareItems: [23, 13],
            gridSquares: [],
            factor: 0,
            scale: 1
        }
    }

    componentDidMount() {
        this.getCorrectGridSize()
        this.updateGrid()
        this.addResizeListner()
    }

    render() {
        const { gridSquares, height, width, scale } = this.state;
        const { overflow } = this.props;

        return (
            <GridContainer id="gridcont" height={height} width={width} overflow={overflow} scale={scale}>
                {gridSquares.map((square, i) => {
                    return <GridSquare color={colors.palette[Math.floor(Math.random() * (colors.palette.length - 1))]} 
                                       key={`square-${i}`} 
                                       id={`square-${i}`}
                                       trail={true}
                                       />
                })}
                {this.props.children}
            </GridContainer>
        );
    }

    // Helpers

    updateGrid() {
        let { squares, gridSquares } = this.state;

        if (gridSquares.length) gridSquares = [];
        
        for (let i = 0; i < squares; i++) {
            gridSquares.push(1)
        }
        
        this.setState({ gridSquares: gridSquares }, () => {
            this.props.gridReady(this.state.factor, this.state.squareItems)
        })
    }

    addResizeListner = () => {
        window.addEventListener('resize', this.getCorrectGridSize)
    }

    getCorrectGridSize = () => {
        if (this.props.long) {
            this.setGrid('width', GridSizes.width[1920].width, 56, 0);
            return;
        }
        // Width
        if (window.innerWidth > 1531 && this.state.width !== GridSizes.width[1920].width) {
            this.setGrid('width', GridSizes.width[1920].width, GridSizes.width[1920].squareItems, 0);
        }

        if (window.innerWidth < 1531 && window.innerWidth > 1390 && this.state.width !== GridSizes.width[1530].width) {
            this.setGrid('width', GridSizes.width[1530].width, GridSizes.width[1530].squareItems, 1);
        }

        if (window.innerWidth < 1391 && window.innerWidth > 1270 && this.state.width !== GridSizes.width[1390].width) {
            this.setGrid('width', GridSizes.width[1390].width, GridSizes.width[1390].squareItems, 2);
        }

        if (window.innerWidth < 1271 && window.innerWidth > 1140 && this.state.width !== GridSizes.width[1270].width) {
            this.setGrid('width', GridSizes.width[1270].width, GridSizes.width[1270].squareItems, 3);
        }

        if (window.innerWidth < 1141 && window.innerWidth > 1000 && this.state.width !== GridSizes.width[1140].width) {
            this.setGrid('width', GridSizes.width[1140].width, GridSizes.width[1140].squareItems, 4);
        }

        if (window.innerWidth < 1024 && window.innerWidth > 600 && this.state.width !== GridSizes.width[1024].width) {
            this.setGrid('width', GridSizes.width[1024].width, GridSizes.width[1024].squareItems, 9);
        }

        if (window.innerWidth < 600 && this.state.width !== GridSizes.width[570].width) {
            this.setGrid('width', GridSizes.width[570].width, GridSizes.width[570].squareItems, 9);
        }

        // Height

        if (window.innerHeight > 853 && window.innerWidth > 1024) {
            this.setState({ scale: 1 });
        }
        if (window.innerHeight < 854 && window.innerHeight > 812 && window.innerWidth > 1024) {
            this.setState({ scale: 0.9 })
        }
        if (window.innerHeight < 812 && window.innerWidth > 1024) {
            this.setState({ scale: 0.8 })
        }
    }

    setGrid = (type, dimension, squareItem, factor) => {
        if (type === 'width') {
            this.setState({ width: dimension, squareItems: [squareItem, this.state.squareItems[1]], squares: (squareItem * this.state.squareItems[1]) - (window.innerWidth < 812 ? 2 : 0), factor: factor }, () => {
                // console.log(this.state.squares)
                this.updateGrid()
            });
        } else {
            this.setState({ height: dimension, squareItems: [this.state.squareItems[0], squareItem], squares: this.state.squareItems[0] * squareItem, factor: factor }, () => {
                // console.log(this.state.squares)
                this.updateGrid()
            });
        }
    }
}

export default Grid;