import React, { Component } from "react";
import Grid from "../Components/Grid";
import Colors from "../Utils/Colors";
import { CategoryContext } from "../Utils/Context";
import {
  setMenuButtons,
  setTitle,
  removeCategory,
  createCategory,
  getImageOrientation,
} from "../Utils/GridControl";
import Title from "../Components/GridItems/Title";
import { Link, withRouter } from "react-router-dom";
import Button from "../Components/GridItems/Button";
import {
  ButtonItem,
  MobileProjectContainer,
  MobileProject,
} from "../Components/GridItems/GridItems.styles";
import { Category } from "../Components/GridItems/Buttons/Buttons.styles";
import ProjectCard from "../Components/GridItems/ProjectCard/ProjectCard";
import { getAllHighlights, getAllProjects } from "../Utils/api";
import PopUp from "../Components/PopUp";
import Window from "../Components/Window";

const colors = new Colors();

class HomePage extends Component {
  constructor(props) {
    super(props);
    this.state = {
      gridReady: false,
      selectedCategory: null,
      factor: 0,
      squareItems: [23, 13],
      projects: {},
      highlights: null,
      popUpOpen: false,
      navigated: false,
    };
  }

  componentDidMount() {
    getAllHighlights()
      .then((highlights) => {
        console.log(highlights);
        this.setState({ highlights: highlights });
      })
      .catch((err) => console.log(err));

    getAllProjects()
      .then((projects) => {
        this.setState({ projects: projects });
      })
      .catch((err) => console.log(err));

    document.addEventListener("category-selected", (event) => {
      window.location.hash = event.category;

      if (event.category !== this.state.selectedCategory) {
        console.log("Found Cat in hash");
        this.clearDisplayedProjects();
        this.setState(
          { selectedCategory: event.category, popUpOpen: false },
          () => {
            const ev = new Event("update:square");
            document.dispatchEvent(ev);
          }
        );
      } else {
        console.log("No Cat in hash");
        this.clearDisplayedProjects();
        this.setState({ selectedCategory: null, popUpOpen: true }, () => {
          const ev = new Event("update:square");
          document.dispatchEvent(ev);
          window.location.hash = "";
        });
      }
    });

    this.props.history.listen((location, action) => {
      // location is an object like window.location
      if (location.pathname === "/" && window.location.hash === "") {
        console.log(location.pathname === "/", "Deleting EVERYTHING");
        this.clearDisplayedProjects();
        this.setState({ selectedCategory: null, popUpOpen: true }, () => {
          const ev = new Event("update:square");
          document.dispatchEvent(ev);
        });
      }
    });

    if (window.location.hash === "")
      setTimeout(() => this.setState({ popUpOpen: true }), 1000);
  }

  componentDidUpdate(prevProps, prevState) {
    if (!prevState.gridReady && this.state.gridReady) {
      setTitle();
      setMenuButtons(this.state.squareItems);
      this.setCategories();
      this.checkLastSelectedCategory();
    }

    if (prevState.selectedCategory !== this.state.selectedCategory) {
      this.updateDisplayedProjects();
      this.setCategories();
    }
  }

  render() {
    const { categories } = this.props;
    const { selectedCategory, projects, highlights, popUpOpen } = this.state;

    if (!projects.threed) return null;

    return (
      <CategoryContext.Provider value={selectedCategory}>
        <Grid
          long={window.innerWidth > 1024 ? false : true}
          gridReady={(factor, squareItems) => {
            this.clearCategories();
            this.clearDisplayedProjects();
            this.setState(
              { gridReady: true, factor: factor, squareItems: squareItems },
              () => {
                if (window.innerWidth < 1025) return;

                this.setCategories();
                setMenuButtons(this.state.squareItems);
                this.updateDisplayedProjects();
                const event = new Event("update:square");
                document.dispatchEvent(event);
              }
            );
          }}
          overflow={popUpOpen}
        >
          {window.innerWidth < 1025 ? (
            <>
              <Link to="/">
                <Title />
              </Link>
              <Link to="/about">
                <Button
                  height={1}
                  width={2}
                  title="About"
                  color={colors.palette[1]}
                  pathActive={"/about" === this.props.history.location.pathname}
                  style={{ right: 0, top: "1px", left: "auto" }}
                />
              </Link>
              <Link to="/blog">
                <Button
                  height={1}
                  width={2}
                  title="Blog"
                  color={colors.palette[1]}
                  pathActive={"/about" === this.props.history.location.pathname}
                  style={{ right: 0, top: "56px", left: "auto" }}
                />
              </Link>
              <MobileProjectContainer>
                <MobileProject color={categories.work.color}>
                  <Category height={1} width={3}>
                    <ButtonItem active={true} color={categories.work.color}>
                      <h2>
                        {categories.work.name}
                        <span>{6}</span>
                      </h2>
                    </ButtonItem>
                  </Category>
                  {projects.work.map((project, i) => {
                    return (
                      <ProjectCard
                        mobile
                        name={project.name}
                        cardOnly={project.cardOnly}
                        skills={project.skills}
                        description={project.projectDescription}
                        slug={project.slug}
                        color={categories.work.color}
                        index={i}
                        fill={project.fill}
                        center={project.imageCenter}
                        image={project.previewImage.fields.file.url}
                        orientation={getImageOrientation(
                          project.previewImage.fields.file.details.image.width,
                          project.previewImage.fields.file.details.image.height
                        )}
                      />
                    );
                  })}
                  <MobileProject color={categories.vr.color}>
                    <Category height={1} width={3}>
                      <ButtonItem active={true} color={categories.vr.color}>
                        <h2>
                          {categories.vr.name}
                          <span>{2}</span>
                        </h2>
                      </ButtonItem>
                    </Category>
                    {projects.vr.map((project, i) => {
                      return (
                        <ProjectCard
                          mobile
                          name={project.name}
                          cardOnly={project.cardOnly}
                          skills={project.skills}
                          description={project.projectDescription}
                          slug={project.slug}
                          color={categories.vr.color}
                          index={i}
                          fill={project.fill}
                          center={project.imageCenter}
                          image={project.previewImage.fields.file.url}
                          orientation={getImageOrientation(
                            project.previewImage.fields.file.details.image
                              .width,
                            project.previewImage.fields.file.details.image
                              .height
                          )}
                        />
                      );
                    })}
                  </MobileProject>
                  <MobileProject color={categories.apps.color}>
                    <Category height={1} width={3}>
                      <ButtonItem active={true} color={categories.apps.color}>
                        <h2>
                          {categories.apps.name}
                          <span>{3}</span>
                        </h2>
                      </ButtonItem>
                    </Category>
                    {projects.apps.map((project, i) => {
                      return (
                        <ProjectCard
                          mobile
                          name={project.name}
                          cardOnly={project.cardOnly}
                          skills={project.skills}
                          description={project.projectDescription}
                          slug={project.slug}
                          color={categories.apps.color}
                          index={i}
                          fill={project.fill}
                          center={project.imageCenter}
                          image={project.previewImage.fields.file.url}
                          orientation={getImageOrientation(
                            project.previewImage.fields.file.details.image
                              .width,
                            project.previewImage.fields.file.details.image
                              .height
                          )}
                        />
                      );
                    })}
                  </MobileProject>
                  <MobileProject color={categories.web.color}>
                    <Category height={1} width={3}>
                      <ButtonItem active={true} color={categories.web.color}>
                        <h2>
                          {categories.web.name}
                          <span>{4}</span>
                        </h2>
                      </ButtonItem>
                    </Category>
                    {projects.web.map((project, i) => {
                      return (
                        <ProjectCard
                          mobile
                          name={project.name}
                          cardOnly={project.cardOnly}
                          skills={project.skills}
                          description={project.projectDescription}
                          slug={project.slug}
                          color={categories.web.color}
                          index={i}
                          fill={project.fill}
                          center={project.imageCenter}
                          image={project.previewImage.fields.file.url}
                          orientation={getImageOrientation(
                            project.previewImage.fields.file.details.image
                              .width,
                            project.previewImage.fields.file.details.image
                              .height
                          )}
                        />
                      );
                    })}
                  </MobileProject>
                  <MobileProject color={categories.ar.color}>
                    <Category height={1} width={3}>
                      <ButtonItem active={true} color={categories.ar.color}>
                        <h2>
                          {categories.ar.name}
                          <span>{5}</span>
                        </h2>
                      </ButtonItem>
                    </Category>
                    {projects.ar.map((project, i) => {
                      return (
                        <ProjectCard
                          mobile
                          name={project.name}
                          cardOnly={project.cardOnly}
                          skills={project.skills}
                          description={project.projectDescription}
                          slug={project.slug}
                          color={categories.ar.color}
                          index={i}
                          fill={project.fill}
                          center={project.imageCenter}
                          image={project.previewImage.fields.file.url}
                          orientation={getImageOrientation(
                            project.previewImage.fields.file.details.image
                              .width,
                            project.previewImage.fields.file.details.image
                              .height
                          )}
                        />
                      );
                    })}
                  </MobileProject>
                </MobileProject>
                <MobileProject color={categories.threed.color}>
                  <Category height={1} width={3}>
                    <ButtonItem active={true} color={categories.threed.color}>
                      <h2>
                        {categories.threed.name}
                        <span>{1}</span>
                      </h2>
                    </ButtonItem>
                  </Category>
                  {projects.threed.map((project, i) => {
                    return (
                      <ProjectCard
                        mobile
                        name={project.name}
                        cardOnly={project.cardOnly}
                        skills={project.skills}
                        description={project.projectDescription}
                        slug={project.slug}
                        color={categories.threed.color}
                        index={i}
                        fill={project.fill}
                        center={project.imageCenter}
                        image={project.previewImage.fields.file.url}
                        orientation={getImageOrientation(
                          project.previewImage.fields.file.details.image.width,
                          project.previewImage.fields.file.details.image.height
                        )}
                      />
                    );
                  })}
                </MobileProject>
              </MobileProjectContainer>
              {/* <SecondCategory height={1} width={2}>
                                <ButtonItem active={selectedCategory === categories.vr.name} color={categories.vr.color}>
                                    <h2>{categories.vr.name}<span>{2}</span></h2>
                                </ButtonItem>
                            </SecondCategory> */}
            </>
          ) : null}
          <PopUp
            open={popUpOpen}
            close={() => this.setState({ popUpOpen: false })}
          />
          {highlights &&
            highlights.map((highlight) => (
              <Window
                hide={!popUpOpen}
                open={popUpOpen}
                headline={highlight.fields.title}
                image={highlight.fields.image.fields.file.url}
                letters={highlight.fields.letters}
                number={highlight.fields.number}
                position={highlight.fields.position}
              />
            ))}
        </Grid>
      </CategoryContext.Provider>
    );
  }

  // Helpers

  setCategories = () => {
    const { categories } = this.props;
    const { projects } = this.state;

    let first = document.getElementById(
      `square-${categories.work.square - 11 * this.state.factor}`
    );
    let second = document.getElementById(
      `square-${categories.vr.square - 11 * this.state.factor}`
    );
    let third = document.getElementById(
      `square-${categories.apps.square - 11 * this.state.factor}`
    );
    let fourth = document.getElementById(
      `square-${categories.web.square - 15 * this.state.factor}`
    );
    let fifth = document.getElementById(
      `square-${categories.ar.square - 15 * this.state.factor}`
    );
    let sixth = document.getElementById(
      `square-${categories.threed.square - 15 * this.state.factor}`
    );

    createCategory(
      first,
      categories.work.height,
      categories.work.width,
      categories.work.name,
      projects.work.length,
      categories.work.color
    );
    createCategory(
      second,
      categories.vr.height,
      categories.vr.width,
      categories.vr.name,
      projects.vr.length,
      categories.vr.color
    );
    createCategory(
      third,
      categories.apps.height,
      categories.apps.width,
      categories.apps.name,
      projects.apps.length,
      categories.apps.color
    );
    createCategory(
      fourth,
      categories.web.height,
      categories.web.width,
      categories.web.name,
      projects.web.length,
      categories.web.color
    );
    createCategory(
      fifth,
      categories.ar.height,
      categories.ar.width,
      categories.ar.name,
      projects.ar.length,
      categories.ar.color
    );
    createCategory(
      sixth,
      categories.threed.height,
      categories.threed.width,
      categories.threed.name,
      projects.threed.length,
      categories.threed.color
    );
  };

  clearCategories = () => {
    const { categories } = this.props;

    let first = document.getElementById(
      `square-${categories.work.square - 11 * this.state.factor}`
    );
    let second = document.getElementById(
      `square-${categories.vr.square - 11 * this.state.factor}`
    );
    let third = document.getElementById(
      `square-${categories.apps.square - 11 * this.state.factor}`
    );
    let fourth = document.getElementById(
      `square-${categories.web.square - 15 * this.state.factor}`
    );
    let fifth = document.getElementById(
      `square-${categories.ar.square - 15 * this.state.factor}`
    );
    let sixth = document.getElementById(
      `square-${categories.threed.square - 15 * this.state.factor}`
    );
    let aboutButton = document.getElementById(
      `square-${this.state.squareItems[0] - 2}`
    );
    let blogButton = document.getElementById(
      `square-${this.state.squareItems[0] * 2 - 2}`
    );

    blogButton.classList.add("blog-button");
    aboutButton.classList.add("about-button");

    removeCategory(aboutButton);
    removeCategory(blogButton);

    removeCategory(first);
    removeCategory(second);
    removeCategory(third);
    removeCategory(fourth);
    removeCategory(fifth);
    removeCategory(sixth);
  };

  updateDisplayedProjects = () => {
    const { categories } = this.props;
    const { selectedCategory, projects } = this.state;

    switch (selectedCategory) {
      case "3D":
        this.addProjects(projects.threed, categories.threed.color);
        break;
      case "VR":
        this.addProjects(projects.vr, categories.vr.color);
        break;
      case "Apps":
        this.addProjects(projects.apps, categories.apps.color);
        break;
      case "Web":
        this.addProjects(projects.web, categories.web.color);
        break;
      case "AR":
        this.addProjects(projects.ar, categories.ar.color);
        break;
      case "Work":
        this.addProjects(projects.work, categories.work.color);
        break;
      default:
        return;
    }
  };

  clearDisplayedProjects = () => {
    const { selectedCategory, projects } = this.state;

    switch (selectedCategory) {
      case "3D":
        this.removeProjects(projects.threed);
        break;
      case "VR":
        this.removeProjects(projects.vr);
        break;
      case "Apps":
        this.removeProjects(projects.apps);
        break;
      case "Web":
        this.removeProjects(projects.web);
        break;
      case "AR":
        this.removeProjects(projects.ar);
        break;
      case "Work":
        this.removeProjects(projects.work);
        break;
      default:
        return;
    }
  };

  removeProjects = (projects) => {
    projects.forEach((project, index) => {
      const element = document.getElementById(
        `square-${project.gridPositions.positions[this.state.factor]}`
      );

      element.classList.remove("show-project");
      element.removeAttribute("project");
      element.removeAttribute("color");
      element.removeAttribute("index");
    });
  };

  addProjects = (projects, color) => {
    projects.forEach((project, index) => {
      const element = document.getElementById(
        `square-${project.gridPositions.positions[this.state.factor]}`
      );

      element.classList.add("show-project");
      element.setAttribute("project", JSON.stringify(project));
      element.setAttribute("color", color);
      element.setAttribute("index", index + 1);
    });
  };

  checkLastSelectedCategory = () => {
    console.log("HASH:", window.location.hash);

    if (window.location.hash) {
      this.setState(
        {
          selectedCategory: window.location.hash
            .split("#")[1]
            .split("%20")
            .join(" "),
        },
        () => {
          const ev = new Event("update:square");
          document.dispatchEvent(ev);
          console.log(this.state.selectedCategory);
        }
      );
    } else {
      this.setState({ selectedCategory: null });
    }
  };
}

export default withRouter(HomePage);
