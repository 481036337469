import React, { Component } from 'react';
import styled, { css } from 'styled-components/macro';
import Title from './GridItems/Title';
import Button from './GridItems/Button';
import ProjectCard from './GridItems/ProjectCard/ProjectCard';
import { Link } from 'react-router-dom';
import { getImageOrientation } from '../Utils/GridControl';

const Square = styled.div`
    position: relative;
    width: 65px;
    height: 65px;
    margin: 0;
    border-top: 1px solid black;
    border-left: 1px solid black;

    transition: background-color 2s;

    ${({ trail }) => trail && css`
        &:hover {
            background-color: ${({ color }) => color};
            transition-duration: .1s;
        }
    `}

    @media (max-width: 1024px) {
        width: 55px;
        height: 55px;
    }

`

class GridSquare extends Component {
    constructor(props) {
        super(props);
        this.state = { 
            title: false,
            category: false,
            project: null,
            button: '',
            path: ''
         }

        this.node = null;
    }

    componentDidMount() {
        document.addEventListener('update:square', this.checkClasses);
    }

    checkClasses = () => {
        if (this.node && this.node.classList.contains('title')) {
            this.setState({ title: true });
        }

        if (this.node && this.node.classList.contains('category')) {
            // console.log(this.node.getAttribute('link'), this.node.getAttribute('active-path'))
            if (this.node.getAttribute('link')) {
                this.setState({ category: true, button: this.node.getAttribute('link'), path: this.node.getAttribute('active-path') });
            } else {
                this.setState({ category: true });
            }
        }

        if (this.node && this.node.classList.contains('show-project')) {
            this.setState({ project: JSON.parse(this.node.getAttribute("project")) });
        } else {
            this.setState({ project: null })
        }
    }

    render() { 
        const { title, category, project, button, path } = this.state;
        const { color, id, trail } = this.props;

        return ( 
            <Square color={color} id={id} ref={div => this.node = div} trail={trail}>
                {title ? 
                <Link to="/">
                    <Title /> 
                </Link>
                : null}
                {category && button.length ? 
                <Link to={button}>
                    <Button height={this.node.getAttribute('height')} 
                            width={this.node.getAttribute('width')} 
                            title={this.node.getAttribute('title')} 
                            number={this.node.getAttribute('order')}
                            color={this.node.getAttribute('color')}
                            pathActive={button === path}
                    />
                </Link>
                : null}
                {category && !button.length ? 
                <Button height={this.node.getAttribute('height')} 
                        width={this.node.getAttribute('width')} 
                        title={this.node.getAttribute('title')} 
                        number={this.node.getAttribute('order')}
                        color={this.node.getAttribute('color')}
                />
                : null}
                {project ? 
                    <ProjectCard 
                            cardOnly={project.cardOnly}
                            skills={project.skills}
                            description={project.projectDescription}
                            slug={project.slug}
                            name={project.name}
                            color={this.node.getAttribute('color')}
                            index={this.node.getAttribute('index')}
                            fill={project.fill}
                            center={project.imageCenter}
                            image={project.previewImage.fields.file.url}
                            orientation={getImageOrientation(project.previewImage.fields.file.details.image.width, project.previewImage.fields.file.details.image.height)}
                    />
                : null}
            </Square>
         );
    }
}
 
export default GridSquare;