import { createClient } from "contentful";

const contentful = createClient({
  space: "39ysbradso29",
  accessToken: "ORXX6uBvCCTBx-ymgVqgJbTfnSvoaNZ0-0-SA3_ORfI",
});

export const getAllProjects = () => {
  let projects = {
    threed: [],
    vr: [],
    apps: [],
    web: [],
    ar: [],
    work: [],
  };

  return contentful
    .getEntries({
      content_type: "project",
    })
    .then(function (entries) {
      console.log(entries);
      entries.items.forEach(function (entry) {
        switch (entry.fields.type) {
          case "3D":
            projects.threed.push(entry.fields);
            break;
          case "VR":
            projects.vr.push(entry.fields);
            break;
          case "Apps":
            projects.apps.push(entry.fields);
            break;
          case "Web":
            projects.web.push(entry.fields);
            break;
          case "AR":
            projects.ar.push(entry.fields);
            break;
          case "Work":
            projects.work.push(entry.fields);
            break;
          default:
            return;
        }
      });

      console.log(projects);

      return projects;
    });
};

export const getAllHighlights = () => {
  return contentful
    .getEntries({
      content_type: "highlights",
    })
    .then(function (entries) {
      return entries.items;
    });
};

export const getAllBlogs = () => {
  return contentful
    .getEntries({
      content_type: "blogPost",
    })
    .then(function (entries) {
      return entries.items;
      // entries.items.forEach(function (entry) {
      //     console.log(entry)
      // })
    });
};

export const getProject = (slug) => {
  return contentful
    .getEntries({
      content_type: "project",
      "fields.slug[in]": slug,
    })
    .then(function (entries) {
      console.log(entries);
      return entries.items[0].fields;
      // entries.items.forEach(function (entry) {
      //     console.log(entry)
      // })
    });
};
