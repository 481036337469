import React, { Component } from "react";
import { Link } from "react-router-dom";

import Grid from "../Components/Grid";
import Title from "../Components/GridItems/Title";
import Button from "../Components/GridItems/Button";
import { setMenuButtons, setTitle, removeCategory } from "../Utils/GridControl";

import { getProject } from "../Utils/api";
import Colors from "../Utils/Colors";
import ProjectContent from "../Components/ProjectContent";

const colors = new Colors();

class ProjectPage extends Component {
  constructor(props) {
    super(props);
    this.state = {
      gridReady: false,
      squareItems: [23, 13],
      loaded: false,
      project: null,
      color: "white",
      descriptionOpen: true,
      slug: "",
    };

    this.item = null;
    this.progress = 0;
  }

  componentDidMount() {
    const { name } = this.props.match.params;
    const { categories } = this.props;

    getProject(name).then((project) => {
      console.log(
        project.type === "3D"
          ? "threed"
          : project.type.toLowerCase().replace(/\s/g, ""),
        categories
      );
      this.setState(
        {
          color:
            categories[
              project.type === "3D"
                ? "threed"
                : project.type.toLowerCase().replace(/\s/g, "")
            ].color,
          project: project,
        },
        () => {}
      );
    });
  }

  componentDidUpdate(prevProps, prevState) {
    if (prevProps.location !== this.props.location) {
      const { name } = this.props.match.params;
      const { categories } = this.props;

      getProject(name).then((project) => {
        this.setState(
          {
            color:
              categories[
                project.type === "3D"
                  ? project.type
                  : project.type.toLowerCase().replace(/\s/g, "")
              ].color,
            project: project,
          },
          () => {}
        );
      });
    }

    if (!prevState.gridReady && this.state.gridReady) {
      if (window.innerWidth < 1025) return;
      // setTitle();
      setMenuButtons(this.state.squareItems);
    }
  }

  render() {
    const { color, project, descriptionOpen } = this.state;

    return (
      <Grid
        overflow="true"
        gridReady={(factor, squareItems) => {
          this.clearCategories();
          this.setState({ gridReady: true, squareItems: squareItems }, () => {
            if (window.innerWidth < 1025) return;
            setMenuButtons(
              this.state.squareItems,
              this.props.history.location.pathname
            );
            const event = new Event("update:square");
            document.dispatchEvent(event);
          });
        }}
      >
        {project ? (
          <ProjectContent
            color={color}
            project={project}
            descriptionOpen={descriptionOpen}
          />
        ) : null}
        {window.innerWidth < 1025 ? (
          <>
            <Link to="/">
              <Title />
            </Link>
            <Link to="/about">
              <Button
                leftHighlight
                height={1}
                width={2}
                title="About"
                color={colors.palette[1]}
                pathActive={"/about" === this.props.history.location.pathname}
                style={{ right: 0, top: "1px", left: "auto" }}
              />
            </Link>
            <Link to="/blog">
              <Button
                leftHighlight
                height={1}
                width={2}
                title="Blog"
                color={colors.palette[7]}
                pathActive={"/blog" === this.props.history.location.pathname}
                style={{ right: 0, top: "56px", left: "auto" }}
              />
            </Link>
          </>
        ) : null}
      </Grid>
    );
  }

  // Helpers

  clearCategories = () => {
    let aboutButton = document.getElementById(
      `square-${this.state.squareItems[0] - 2}`
    );
    let blogButton = document.getElementById(
      `square-${this.state.squareItems[0] * 2 - 2}`
    );

    blogButton.classList.add("blog-button");
    aboutButton.classList.add("about-button");

    removeCategory(aboutButton);
    removeCategory(blogButton);
  };
}

export default ProjectPage;
