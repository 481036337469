import React, { Component } from "react";
import { documentToReactComponents } from "@contentful/rich-text-react-renderer";
import { gsap } from "gsap";
import {
  ProjectItem,
  ProjectPreviewImage,
  ProjectTitle,
  ProjectSpacer,
  Trail,
  Overlay,
  PatternImage,
  Image,
  PreviewImage,
  ColorLine,
  CardInfo,
  SmallProjectDescription,
  Skills,
} from "./ProjectCard.styles";

import pat1 from "../../../assets/2 2.png";
import pat2 from "../../../assets/Group 13.png";
import pat3 from "../../../assets/Group 3.png";
import { Link } from "react-router-dom";

class ProjectCard extends Component {
  constructor(props) {
    super(props);
    this.state = {
      active: false,
      loaded: false,
      showInfo: false,
    };

    this.ref = null;
    this.trail = null;
    this.filter = null;
    this.patterns = [pat1, pat2, pat3];
  }

  componentDidMount() {
    // this.ref.addEventListener('mousemove', this.move)
  }

  move = (e) => {
    if (this.state.active) {
      gsap.to(this.trail, { duration: 0.5, left: e.offsetX, top: e.offsetY });
    }
  };

  hide = () => {
    if (this.state.active) {
      gsap.to(this.trail, { duration: 0.3, scale: 0.9, autoAlpha: 0 });
      this.setState({ active: false });
    }
  };

  show = () => {
    if (!this.state.active) {
      gsap.to(this.trail, { duration: 0.3, scale: 1, autoAlpha: 1 });
      this.setState({ active: true }, () => {
        var turbVal = { val: 0.0001 };
        var turb = document.querySelectorAll("#filter feTurbulence")[0];
        var btTl = gsap.timeline({
          paused: true,
          onUpdate: function () {
            turb.setAttribute("baseFrequency", "0 " + turbVal.val);
          },
        });

        btTl.to(turbVal, { duration: 0.2, delay: 0.1, val: 0.3 });
        btTl.to(turbVal, { duration: 0.2, delay: 0.1, val: 0.000001 });

        btTl.restart();
      });
    }
  };

  render() {
    const {
      color,
      index,
      name,
      fill,
      image,
      orientation,
      center,
      mobile,
      cardOnly,
      slug,
      skills,
      description,
    } = this.props;
    const { loaded, showInfo } = this.state;

    if (cardOnly) {
      return (
        <ProjectItem
          fill={fill}
          mobile={mobile}
          index={index}
          cardOnly={cardOnly}
          onMouseEnter={() => this.setState({ showInfo: true })}
          onMouseLeave={() => this.setState({ showInfo: false })}
        >
          {fill === "top" ? <ProjectSpacer fill={fill} /> : null}
          {showInfo ? (
            <CardInfo fill={fill}>
              <SmallProjectDescription color={color} show={showInfo}>
                {documentToReactComponents(description)}
              </SmallProjectDescription>
              <Skills show={showInfo}>
                <p>
                  {skills.map((skill, i) =>
                    skills.length === i + 1 ? `${skill}. ` : `${skill}, `
                  )}
                </p>
              </Skills>
            </CardInfo>
          ) : null}
          <ProjectPreviewImage
            color={color}
            fill={fill}
            ref={(div) => (this.ref = div)}
          >
            <PatternImage>
              <img
                alt="pattern"
                src={
                  this.patterns[
                    Math.floor(Math.random() * this.patterns.length)
                  ]
                }
              />
            </PatternImage>
            <PreviewImage color={color}>
              <Image
                alt="project"
                center={center}
                src={image + "?q=25"}
                orientation={orientation}
                onLoad={loaded ? null : () => this.setState({ loaded: true })}
                loaded={loaded}
                delay={0}
              />
              <Overlay color={color} opacity={Math.random() - 0.1} />
            </PreviewImage>
            {/* <ColorLine color={color} /> */}
            <Trail ref={(div) => (this.trail = div)} orientation={orientation}>
              <div className="filt"></div>
              <img alt={"test"} src={image} />
            </Trail>
          </ProjectPreviewImage>
          <ProjectTitle fill={fill}>
            <p>
              <span>{index}</span>
              {name}
            </p>
          </ProjectTitle>
          {fill === "bottom" ? <ProjectSpacer fill={fill} /> : null}
        </ProjectItem>
      );
    } else {
      return (
        <Link to={{ pathname: "/project/" + slug, state: { color: color } }}>
          <ProjectItem fill={fill} mobile={mobile} index={index}>
            {fill === "top" ? <ProjectSpacer fill={fill} /> : null}
            <ProjectPreviewImage
              className="clickable"
              color={color}
              fill={fill}
              ref={(div) => (this.ref = div)}
            >
              <PatternImage>
                <img
                  alt="pattern"
                  src={
                    this.patterns[
                      Math.floor(Math.random() * this.patterns.length)
                    ]
                  }
                />
              </PatternImage>
              <PreviewImage color={color}>
                <Image
                  alt="project"
                  center={center}
                  src={image + "?q=25"}
                  orientation={orientation}
                  onLoad={loaded ? null : () => this.setState({ loaded: true })}
                  loaded={loaded}
                  delay={0}
                />
                <Overlay color={color} opacity={Math.random() - 0.1} />
              </PreviewImage>
              {/* <ColorLine color={color} /> */}
              <Trail
                ref={(div) => (this.trail = div)}
                orientation={orientation}
              >
                <div className="filt"></div>
                <img alt={"test"} src={image} />
              </Trail>
            </ProjectPreviewImage>
            <ProjectTitle fill={fill}>
              <p>
                <span>{index}</span>
                {name}
              </p>
            </ProjectTitle>
            {fill === "bottom" ? <ProjectSpacer fill={fill} /> : null}
          </ProjectItem>
        </Link>
      );
    }
  }
}

export default ProjectCard;
