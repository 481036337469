import React from 'react';
import styled, { css } from 'styled-components/macro';
import { ButtonContainer } from '../GridItems.styles';

import next from '../../../assets/next.png';
import previous from '../../../assets/previous.png';

export const MobileCategory = styled(ButtonContainer)`
    & h2 {
        font-size: 15px;
    }

    & h2 span {
        font-size: 9px;
        margin: 0px 3px;
    }
`

export const Category = styled(MobileCategory)`
    position: relative;
    top: 0px;
    left: 82px;

    @media (max-width: 1024px) {
        position: absolute;
        left: 56px;
    }

    @media (max-width: 600px) {
        position: relative;
        top: 0px;
        left: 82px;
    }
`

export const SolidButtonContainer = styled.div`
    position: absolute;
    bottom: 0px;
    right: 65px;
    height: 64px;
    width: 64px;
    background-color: ${({ color }) => color};
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;

    ${({ type }) => type === "next" && css`
        right: 0px;
    `}

    & img {
        width: 50%;
    }

    @media (max-width: 1024px) {
        display: none;
    }
`

export const CloseButtonContainer = styled.div`
    position: absolute;
    top: 1px;
    right: 130px;
    height: 64px;
    width: 64px;
    background-color: ${({ color }) => color};
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;

    & img {
        width: 50%;
    }

    @media (max-width: 1024px) {
        display: none;
    }
`

export const ArrowButton = ({ type, color, action }) => {
    return ( 
        <SolidButtonContainer color={color} type={type} onClick={() => action ? action() : null}>
            <img alt="arrow" src={type === "next" ? next : previous}/>
        </SolidButtonContainer>
     );
}

export const CloseButton = ({ color, action }) => {
    return ( 
        <CloseButtonContainer color={color} onClick={() => action ? action() : null}>
            <h1>X</h1>
        </CloseButtonContainer>
     );
}