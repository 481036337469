import React, { useState, useRef, useEffect } from 'react';
import { ButtonItem, ButtonContainer } from './GridItems.styles';
import { useContext } from 'react';
import { CategoryContext } from '../../Utils/Context';

const Button = ({ height, width, children, title, number, color, pathActive, style, leftHighlight }) => {
    const [active, setActive] = useState(false)
    const [categoryColor, setColor] = useState(null)
    const ref = useRef();
    let selected = useContext(CategoryContext)

    useEffect(() => {
        if (ref.current && !categoryColor) {
            setColor(color)
        }
    }, [color, categoryColor])

    const onSelect = () => {
        const event = new Event('category-selected');

        event.category = title;

        document.dispatchEvent(event);
    }
    
    return ( 
        <ButtonContainer height={height} width={width} onClick={() => onSelect()} style={style}>
            <ButtonItem active={selected === title || pathActive} color={categoryColor} ref={ref} leftHighlight={leftHighlight}>
                {children}
                <h2>{title}{number ? <span>{number}</span> : null}</h2>
            </ButtonItem>
        </ButtonContainer>
     );
}
 
export default Button;