import React, { Component } from "react";
import { Link } from "react-router-dom";

import Grid from "../Components/Grid";
import Title from "../Components/GridItems/Title";
import Button from "../Components/GridItems/Button";
import { MdMailOutline } from "react-icons/md";
import { FaLinkedinIn, FaInstagram, FaArtstation } from "react-icons/fa";
import { setMenuButtons, setTitle, removeCategory } from "../Utils/GridControl";
import {
  MiddleCard,
  WorkItem,
  GreetingCard,
  IntroCard,
  SquareImageContainer,
  PhotoCard,
  ContactCard,
  InstagramCard,
  MailCard,
  LinkedInCard,
  ArtStationCard,
} from "../Components/GridItems/InfoItems";
import {
  ProjectItem,
  PatternImage,
  PreviewImage,
  Image,
  Overlay,
} from "../Components/GridItems/ProjectCard/ProjectCard.styles";

import pattern from "../assets/Group 3.png";
import image from "../assets/me_web.jpg";
import Colors from "../Utils/Colors";

const colors = new Colors();

class InfoPage extends Component {
  constructor(props) {
    super(props);
    this.state = {
      gridReady: false,
      squareItems: [23, 13],
      loaded: false,
    };
  }

  componentDidUpdate(prevProps, prevState) {
    if (!prevState.gridReady && this.state.gridReady) {
      if (window.innerWidth < 1025) return;
      setTitle();
      setMenuButtons(this.state.squareItems);
    }
  }

  render() {
    const { loaded } = this.state;

    return (
      <Grid
        gridReady={(factor, squareItems) => {
          this.clearCategories();
          this.setState({ gridReady: true, squareItems: squareItems }, () => {
            if (window.innerWidth < 1025) return;
            setMenuButtons(
              this.state.squareItems,
              this.props.history.location.pathname
            );
            const event = new Event("update:square");
            document.dispatchEvent(event);
          });
        }}
      >
        <GreetingCard>
          <h2>Hello</h2>
        </GreetingCard>
        <IntroCard>
          <h3>I am a creative technologist.</h3>
          <h3>
            My passion is making immersive experiences that blend emerging
            technologies and meaningful storytelling.
          </h3>
        </IntroCard>
        <MiddleCard>
          <h3>RECENT EXPERIENCE</h3>
          <WorkItem>
            <h4>Lead Creative Technologist</h4>
            <p>&why • Munich, Germany</p>
            <p>January 2018 - Present</p>
          </WorkItem>
          <WorkItem>
            <h4>Virtual Production Supervisor</h4>
            <p>Amazon Studios • Mexico City</p>
            <p>February 2023 - December 2023</p>
          </WorkItem>
          <WorkItem>
            <h4>Founding Partner & Technology Adviser</h4>
            <p>Barrilete • San Jose, Costa Rica</p>
            <p>September 2018 - Present</p>
          </WorkItem>
          <WorkItem>
            <h4>Project Manager</h4>
            <p>Maderas Collective • New York</p>
            <p>January 2017 - November 2017</p>
          </WorkItem>
          <WorkItem>
            <h4>Curator & Organizer</h4>
            <p>TEDxManagua • Managua, Nicaragua</p>
            <p>December 2012 - February 2018</p>
          </WorkItem>
        </MiddleCard>
        <PhotoCard>
          <ProjectItem style={{ width: "100%", height: "100%" }}>
            <SquareImageContainer>
              <PatternImage>
                <img alt="pattern" src={pattern} />
              </PatternImage>
              <PreviewImage color={"white"}>
                <Image
                  alt="pattern"
                  src={image}
                  orientation={"portrait"}
                  onLoad={loaded ? null : () => this.setState({ loaded: true })}
                  loaded={loaded}
                />
                <Overlay color={"white"} opacity={Math.random() - 0.1} />
              </PreviewImage>
            </SquareImageContainer>
          </ProjectItem>
        </PhotoCard>
        <ContactCard>
          <h3>Would love to build something awesome together. Hit me up.</h3>
        </ContactCard>
        <a
          href="mailto:jcb8793@gmail.com"
          target="_blank"
          rel="noopener noreferrer"
        >
          <MailCard color={5}>
            <MdMailOutline size="30" />
          </MailCard>
        </a>
        <a
          href="https://www.linkedin.com/in/juancuadrad/"
          target="_blank"
          rel="noopener noreferrer"
        >
          <LinkedInCard color={3}>
            <FaLinkedinIn size="30" />
          </LinkedInCard>
        </a>
        <a
          href="https://www.instagram.com/juancuadra/"
          target="_blank"
          rel="noopener noreferrer"
        >
          <InstagramCard color={4}>
            <FaInstagram size="30" />
          </InstagramCard>
        </a>
        <a
          href="https://www.artstation.com/juancuadra78"
          target="_blank"
          rel="noopener noreferrer"
        >
          <ArtStationCard color={2}>
            <FaArtstation size="30" />
          </ArtStationCard>
        </a>
        {window.innerWidth < 1025 ? (
          <>
            <Link to="/">
              <Title />
            </Link>
            <Link to="/about">
              <Button
                leftHighlight
                height={1}
                width={2}
                title="About"
                color={colors.palette[1]}
                pathActive={"/about" === this.props.history.location.pathname}
                style={{ right: 0, top: "1px", left: "auto" }}
              />
            </Link>
            <Link to="/blog">
              <Button
                leftHighlight
                height={1}
                width={2}
                title="Blog"
                color={colors.palette[1]}
                pathActive={"/blog" === this.props.history.location.pathname}
                style={{ right: 0, top: "56px", left: "auto" }}
              />
            </Link>
          </>
        ) : null}
      </Grid>
    );
  }

  // Helpers

  clearCategories = () => {
    let aboutButton = document.getElementById(
      `square-${this.state.squareItems[0] - 2}`
    );
    let blogButton = document.getElementById(
      `square-${this.state.squareItems[0] * 2 - 2}`
    );

    blogButton.classList.add("blog-button");
    aboutButton.classList.add("about-button");

    removeCategory(aboutButton);
    removeCategory(blogButton);
  };
}

export default InfoPage;
