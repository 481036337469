import styled, { css } from "styled-components/macro";
import Colors from "../../Utils/Colors";

const colors = new Colors();

export const ProjectPageTitle = styled.div`
  position: absolute;
  background: ${({ color }) => color};
  height: 129px;
  width: 454px;
  left: 1px;
  top: 1px;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  align-items: flex-start;
  padding: 25px;
  text-align: left;

  & p {
    font-size: 16px;
    font-weight: 100;
  }

  @media (max-width: 1024px) {
    height: 164px;
    width: 661px;
    left: 1px;
    top: 111px;
    display: none;
    border-bottom: 1px solid black;

    & p {
      font-size: 15px;
    }
  }

  @media (max-width: 600px) {
    width: 329px;
  }
`;

export const ProjectDescription = styled.div`
  position: absolute;
  background: ${({ color }) => color};
  background: ${colors.background};
  height: 584px;
  width: ${({ open }) => (open ? 454 : 64)}px;
  left: 66px;
  top: 196px;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  padding: 25px;
  text-align: left;

  transition: width 0.7s ${({ open }) => (open ? 0 : 0.7)}s;

  & > * {
    opacity: ${({ open }) => (open ? 1 : 0)};

    transition: opacity 0.5s ${({ open }) => (open ? 0.7 : 0)}s;
  }

  & p a {
    position: relative;
  }

  & p a::after {
    content: "";
    width: 100%;
    height: 3px;
    background: ${({ color }) => color};
    position: absolute;
    left: 0;
    bottom: -1px;

    transition-timing-function: cubic-bezier(0.425, 0.005, 0, 1);
    transition: height 0.3s;
  }

  & p a:hover::after {
    height: 40%;
    color: white;
    mix-blend-mode: hard-light;
  }

  & p {
    font-family: "MaisonNeue-Book", Fallback, sans-serif;
    font-size: 16px;
    line-height: 24px;
    font-weight: 100;
    margin-top: 18px;
  }

  & h3 {
    font-family: "MaisonNeue", Fallback, sans-serif;
    font-weight: 300;
    font-size: 21px;
    text-transform: uppercase;
    text-align: left;
    letter-spacing: 1px;
    margin: 0;
  }

  @media (max-width: 1390px) {
    left: 1px;
  }

  @media (max-width: 1260px) {
    height: 714px;
    width: 389px;
    top: 131px;
  }

  @media (max-width: 1024px) {
    width: 661px;
    padding: 18px;
    top: 276px;
    height: 549px;
    overflow-y: scroll;
    display: none;
  }

  @media (max-width: 600px) {
    width: 329px;
  }
`;

export const Content = styled.div`
  position: absolute;
  overflow-y: hidden;
  overflow-x: scroll;
  /* background: ${colors.background}; */
  height: 584px;
  width: 909px;
  left: 521px;
  top: 196px;
  padding-left: 65px;
  pointer-events: none;

  &::-webkit-scrollbar {
    display: none;
  }

  @media (max-width: 1390px) {
    left: 456px;
  }

  @media (max-width: 1260px) {
    left: 391px;
    width: 649px;
  }

  @media (max-width: 1024px) {
    display: none;
  }
`;

export const ContentWrapper = styled.div`
  height: 100%;
  /* width: auto; */
  /* min-width: 1000px; */
  /* min-width: ${({ width }) => width || 1000}px; */
  width: max-content;
  /* background: salmon; */
  display: flex;
  justify-content: flex-start;
  align-items: center;
  flex-wrap: nowrap;
  overflow-x: auto;
  overflow-y: hidden;
`;

export const VideoContent = styled.div`
  position: relative;
  overflow: hidden;
  background: ${({ color }) => color};
  /* height: 454px;
  width: 779px; */
  height: 100%;
  width: auto;
  margin-right: 66px;
  pointer-events: all;
  cursor: pointer;

  & span {
    position: absolute;
    z-index: 1;
    margin: auto;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    width: 100%;
    height: 100%;
    background: ${({ color }) => color};
    mix-blend-mode: screen;
    opacity: 0.6;
  }

  & .cover {
    width: 100%;
    height: 100%;
    background-color: #f7f6f4;
    position: absolute;
    display: ${({ play }) => (play ? "none" : "")};
  }

  & .vid-player {
    /* position: absolute; */
  }

  & .lightbox {
    position: fixed;
    top: 0;
    left: 0%;
    background-color: rgba(0, 0, 0, 0.9);
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 100%;
    z-index: 40;
    display: ${({ play }) => (play ? "flex" : "none")};
  }

  & svg {
    position: absolute;
    z-index: 2;
    margin: auto;
    bottom: 50px;
    left: 50px;
    display: ${({ play }) => (play ? "none" : "unset")};
  }

  & img {
    position: relative;
    /* top: 50%;
    left: 50%;  */
    /* transform: translate(-50%, -50%);
    display: ${({ play }) => (play ? "none" : "unset")};
    width: 100%; */
    height: 100%;
  }

  @media (max-width: 1024px) {
    display: none;
    height: 164px;
    width: 329px;
    position: relative;
    top: 0;
    left: 0;
    margin-right: 0px;
  }
`;

export const ScrollBar = styled.div`
  width: 65px;
  height: 649px;
  position: absolute;
  overflow: hidden;
  right: -1px;
  top: 131px;
  background: ${colors.background};

  &::after {
    content: "";
    position: absolute;
    top: 0px;
    left: 0px;
    width: 100%;
    height: ${({ progress }) => progress || 0}%;
    background-color: ${({ color }) => color};
    opacity: 0.5;
    will-change: height;
    transition: height 0.3s linear;
  }

  @media (max-width: 1024px) {
    display: none;
  }
`;
export const ProjectImage = styled.div`
  position: relative;
  overflow: hidden;
  background: ${({ color }) => color};
  background: #f7f6f4;
  /* height: 454px;
  width: 779px; */
  height: 100%;
  width: auto;
  margin-right: 66px;

  & img {
    position: relative;
    /* top: 50%;
    left: 50%; 
    transform: translate(-50%, -50%); */
    height: 100%;
  }

  ${({ orientation }) =>
    orientation === "landscape" &&
    css`
      & img {
        width: 100%;
      }
    `}
  ${({ orientation }) =>
    orientation === "portrait" &&
    css`
      width: 389px;
      height: 585px;

      & img {
        height: 100%;
      }
    `}

    @media (max-width: 1024px) {
    /* ${({ orientation }) =>
      orientation === "portrait" &&
      css`
        height: 584px;
        width: 100%;
      `}
    ${({ orientation }) =>
      orientation === "landscape" &&
      css`
        width: 661px;
        height: 454px;
      `} */

    margin-right: 0;
    margin-top: 55px;
    position: relative;
    top: 0;
    left: 0;
    background: transparent;
  }

  @media (max-width: 600px) {
    /* ${({ orientation }) =>
      orientation === "portrait" &&
      css`
        height: 384px;
      `}
    ${({ orientation }) =>
      orientation === "landscape" &&
      css`
        width: 329px;
        height: 172px;
      `} */
  }
`;

export const MoreInfoText = styled.div`
  position: absolute;
  bottom: 0px;
  right: 130px;
  height: 64px;
  width: 324px;
  background-color: ${colors.background};
  display: flex;
  justify-content: center;
  align-items: center;

  & > * {
    font-size: 14px;
  }

  @media (max-width: 1024px) {
    display: none;
  }
`;

export const RelatedPostContainer = styled.div`
  height: 187px;
  width: 150px;
  display: flex;
  flex-direction: column;
  border: 1px solid;
  margin-top: 20px;
  margin-right: 15px;

  @media (max-width: 1024px) {
    width: 100%;
    flex-direction: row;
    align-items: center;
    height: 90px;
  }
`;

export const RelatedPostImage = styled.div`
  position: relative;
  overflow: hidden;
  height: 150px;
  width: 148px;

  /* & img {
    height: 100%;
  } */

  ${({ orientation }) =>
    orientation === "landscape" &&
    css`
      & img {
        height: 100%;
      }
    `}
  ${({ orientation }) =>
    orientation === "portrait" &&
    css`
      & img {
        width: 100%;
      }
    `}

  @media (max-width: 1024px) {
    height: 100%;
  }
`;

export const RelatedPostName = styled.div`
  height: 35px;
  width: 100%;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  padding: 0px 8px;
  border-top: 1px solid;

  & > p {
    margin: 0;
    text-transform: lowercase;
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
  }

  @media (max-width: 1024px) {
    border-top: none;
    padding: 5px 13px;
  }
`;

export const MobileProjectContent = styled.div`
  position: absolute;
  background: ${colors.background};
  height: auto;
  min-height: 400px;
  width: 661px;
  left: 1px;
  top: 111px;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  display: none;

  @media (max-width: 1024px) {
    display: unset;

    & ${ProjectPageTitle} {
      display: flex;
      position: relative;
      top: 0;
      left: 0;
    }

    & ${ProjectDescription} {
      display: flex;
      position: relative;
      top: 0;
      left: 0;
      height: auto;
      border-bottom: 1px solid black;
    }
  }

  @media (max-width: 600px) {
    width: 329px;
  }
`;

export const MobileImages = styled.div`
  position: relative;
  background: ${colors.background};
  height: auto;
  width: 661px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  display: none;

  & img {
    font-size: 16px;
    font-weight: 100;
  }

  @media (max-width: 1024px) {
    display: flex;
  }

  @media (max-width: 600px) {
    width: 329px;
  }
`;
