import styled, { css } from "styled-components/macro";
import Colors from "../../Utils/Colors";

const colors = new Colors();

export const GridItem = styled.div`
  position: absolute;
  z-index: 1;
  left: 0;
  top: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: ${({ color }) => color || colors.background};
`;

export const TitleItem = styled(GridItem)`
  width: 194px;
  height: 64px;
  left: 0px;
  top: 0px;
  padding: 10px;
  text-align: left;
  background-color: #4661ff;

  & img {
    width: 60%;
  }

  & h3 {
    font-size: 16px;
    line-height: 26px;
    color: white;
  }

  @media (max-width: 1024px) {
    width: 111px;
    height: 111px;
  }
`;

export const ButtonContainer = styled(GridItem)`
  width: ${({ width }) => width * 65 - 1}px;
  height: ${({ height }) => height * 65 - 1}px;
  background-color: black;
  cursor: pointer;

  ${({ width }) =>
    !width &&
    css`
      opacity: 0;
    `}

  @media (max-width: 1024px) {
    width: ${({ width }) => width * 55 - 1}px;
    height: ${({ height }) => height * 55 - 1}px;
  }
`;

export const ButtonItem = styled.div`
  position: absolute;
  background-color: ${({ active, color }) => (active ? color : colors.grey)};
  left: 0;
  top: 0;
  border: ${({ active }) => (active ? "2px solid black" : "0px solid black")};
  width: 100%;
  height: 100%;
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;

  transform: ${({ active }) =>
    active ? "translate(-5px, -5px)" : "translate(0px, 0px)"};

  transition: border 0.1s, transform 0.2s ease-in-out, background-color 0.2s;

  &:hover {
    background-color: ${({ color }) => color};
  }

  & h2 span {
    font-family: "MonumentalExtended";
    font-weight: 500;
    font-size: 13px;
    margin: 0px 7px;
  }

  @media (max-width: 1024px) {
    ${({ leftHighlight }) =>
      leftHighlight &&
      css`
        transform: ${({ active }) =>
          active ? "translate(-5px, -1px)" : "translate(0px, 0px)"};
      `}
  }
`;

export const MobileProjectContainer = styled.div`
  width: 661px;
  min-height: 500px;
  position: absolute;
  top: 166px;
  left: 1px;
  background-color: black;

  @media (max-width: 600px) {
    width: 332px;
  }
`;

export const MobileProject = styled.div`
  width: 659px;
  min-height: 300px;
  position: relative;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  background: ${({ color }) => color};
  background: ${colors.background};
  border-bottom: 1px solid;
  padding-top: 40px;

  @media (max-width: 600px) {
    width: 331px;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding-top: 0;
  }
`;
