import styled, { css } from "styled-components/macro";
import Colors from "../../../Utils/Colors";
import { GridItem } from "../GridItems.styles";

const colors = new Colors();

export const ProjectItem = styled(GridItem)`
  width: 259px;
  height: 259px;
  display: flex;
  flex-direction: column;
  /* places parent (#circle) on its own rendering layer so overflow hidden is honored */
  -webkit-transform: translateZ(0);
  transform: translateZ(0);

  ${({ fill }) =>
    fill === "top" &&
    css`
      width: 259px;
      height: 325px;
      top: -66px;
    `}

  ${({ fill }) =>
    fill === "bottom" &&
    css`
      width: 259px;
      height: 325px;
    `}

  @media (max-width: 1140px) {
    width: 259px;
    height: 259px;
  }

  @media (max-width: 1024px) {
    ${({ mobile }) =>
      mobile &&
      css`
        width: 220px;
        height: 220px;
        position: unset;
        margin: 35px;
        border: 2px solid;
      `}

    ${({ cardOnly }) =>
      cardOnly &&
      css`
        display: none;
      `}
  }

  @media (max-width: 600px) {
    ${({ mobile }) =>
      mobile &&
      css`
        margin: 0px;
        margin-top: 55px;

        &:last-child {
          margin-bottom: 54px;
        }
      `}
  }
`;

export const ProjectSpacer = styled.div`
  width: 259px;
  height: 67px;
  background-color: ${colors.background};

  ${({ fill }) =>
    fill === "top" &&
    css`
      border-bottom: 1px solid black;
    `}

  ${({ fill }) =>
    fill === "bottom" &&
    css`
      height: 65px;
    `}

    @media(max-width: 1140px) {
    display: none;
  }
`;

export const Overlay = styled.div`
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  opacity: ${({ opacity }) => opacity};
  background: white;
  z-index: 0;

  transition: opacity 0.1s ease-in;
`;

export const ColorLine = styled.div`
  position: absolute;
  left: 0;
  bottom: 0;
  width: 100%;
  height: 4px;
  background: ${({ color }) => color};
  z-index: 2;
  border-top: 1px solid black;

  transition: opacity 0.1s ease-in;
`;

export const PreviewImage = styled.div`
  width: 100%;
  height: 100%;
  position: absolute;
  overflow: hidden;
  left: 0;
  top: 0;
  z-index: 2;
  background-color: ${({ color }) => color};
  border-left: 0px solid black;
  border-right: 0px solid black;
  border-top: 0px solid black;
  border-bottom: 0px solid black;

  transition: transform 0.3s;

  &:hover {
    transform: translate(-15px, -15px);
    border-left: 2px solid black;
    border-right: 2px solid black;
    border-top: 2px solid black;
    border-bottom: 2px solid black;
  }
`;

export const Image = styled.img`
  position: absolute;
  top: 50%; /* position the top  edge of the element at the middle of the parent */
  left: 50%; /* position the left edge of the element at the middle of the parent */
  transform: translate(-50%, -50%);
  z-index: 1;
  opacity: ${({ loaded }) => (loaded ? 1 : 0)};

  transition: opacity 0.8s ${({ delay }) => delay}s ease-out,
    transform ${({ loaded }) => (loaded ? 0.2 : 0.8)}s ease-out;

  ${({ orientation, center }) =>
    orientation === "landscape" &&
    !center &&
    css`
      height: 100%;
    `}
  ${({ orientation, center }) =>
    orientation === "portrait" &&
    !center &&
    css`
      width: 100%;
    `}
    
    ${({ orientation, center }) =>
    orientation === "landscape" &&
    center &&
    css`
      width: 80%;
    `}
    ${({ orientation, center }) =>
    orientation === "portrait" &&
    center &&
    css`
      height: 85%;
    `}
`;

export const PatternImage = styled.div`
  width: 100%;
  height: 100%;
  position: absolute;
  overflow: hidden;
  left: 0;
  top: 0;

  & img {
    position: relative;
    left: -2px;
    top: -2px;
  }
`;

export const ProjectPreviewImage = styled.div`
  width: 100%;
  height: 87.5%;
  background-color: ${({ color }) => color || colors.background};
  /* opacity: 0.6; */
  position: relative;
  /* overflow: hidden; */

  ${({ fill }) =>
    fill &&
    css`
      height: 70%;
    `}

  &.clickable::after {
    content: "👆🏻";
    display: flex;
    justify-content: center;
    align-items: center;
    position: absolute;
    z-index: 21;
    left: -32.5px;
    top: -32.5px;
    width: 65px;
    height: 65px;
    border-radius: 500px;
    border: 1px solid black;
    font-size: 30px;
    background-color: #4661ff;
    color: white;
    transform: scale(0);

    transition-timing-function: cubic-bezier(0.425, 0.005, 0, 1);
    transition: 0.3s;
  }

  &.clickable:hover::after {
    transform: scale(1);
  }

  @media (max-width: 1140px) {
    height: 87.5%;
  }
`;

export const ProjectTitle = styled.div`
  width: 100%;
  height: 12.5%;
  display: flex;
  justify-content: center;
  align-items: flex-start;
  flex-direction: column;
  padding: 0px 10px;
  border-top: 1px solid black;
  position: relative;
  z-index: 3;

  & p {
    text-transform: lowercase;
  }

  & span {
    font-size: 11px;
    margin-right: 5px;
  }

  ${({ fill }) =>
    fill &&
    css`
      height: 10%;
    `}

  ${({ fill }) =>
    fill === "bottom" &&
    css`
      border-bottom: 1px solid black;
    `}

    @media(max-width: 1140px) {
    height: 12.5%;
  }

  @media (max-width: 1024px) {
    border-bottom: none;
  }
`;

export const Trail = styled.div`
  position: absolute;
  overflow: hidden;
  width: 260px;
  height: 226px;
  top: 50%;
  left: 50%;
  margin: -120px 0 0 -120px;
  background: #cb4b16;
  z-index: 2;
  opacity: 0;
  backface-visibility: hidden;
  pointer-events: none;
  -webkit-filter: url("#filter");
  filter: url("/#filter");

  & .filt {
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    /* background: #4ded92;
        z-index: 2;
        mix-blend-mode: soft-light; */
  }

  & img {
    position: absolute;
    top: 50%; /* position the top  edge of the element at the middle of the parent */
    left: 50%; /* position the left edge of the element at the middle of the parent */
    transform: translate(-50%, -50%);
    ${({ orientation }) =>
      orientation === "landscape" &&
      css`
        height: 100%;
      `}
    ${({ orientation }) =>
      orientation === "portrait" &&
      css`
        width: 100%;
      `}
  }
`;

export const Skills = styled.div`
  width: 100%;
  height: ${({ show }) => (show ? 20 : 0)}%;
  text-align: left;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  background: black;
  border-top: 1px solid blakc;
  padding: 0px 18px;
  color: ${colors.background};
  will-change: height;

  & p {
    /* text-transform: lowercase; */
  }
`;

export const CardInfo = styled.div`
  height: 87.5%;
  width: 100%;
  background-color: ${colors.background};
  position: absolute;
  top: 0;
  left: 0;
  z-index: 3;
  display: flex;
  flex-direction: column;

  ${({ fill }) =>
    fill &&
    css`
      height: 70%;
    `}

  ${({ fill }) =>
    fill === "top" &&
    css`
      top: auto;
      bottom: 0;
      height: 79.5%;

      & ${Skills} {
        height: 21%;
      }
    `}

    @media (max-width: 1024px) {
    & p {
      font-size: 13px;
      margin-top: 0px;
    }
  }
`;

export const SmallProjectDescription = styled.div`
  width: 100%;
  height: ${({ show }) => (show ? 80 : 0)}%;
  text-align: left;
  padding: 15px 18px;
  background: ${({ color }) => color};
  will-change: height;

  & p a {
    background-color: ${colors.background};
  }

  & p {
    margin-top: 10px;
  }

  & p:first-child {
    margin-top: 0px;
  }

  @media (max-width: 1024px) {
    & p {
      margin-top: 0px;
    }
  }
`;
