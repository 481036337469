import React from 'react';
import styled from 'styled-components/macro';
import GridSquare from '../Components/GridSquare';
import Colors from '../Utils/Colors';

const colors = new Colors();

export const NotFoundContainer = styled.div`
    width: 100vw;
    height: 100vh;
    display: flex;
    justify-content: center;
    align-items: center;
`;

export const CubeLayer = styled.div`
    width: 326px;
    height: 260px;
    position: relative;
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
    flex-wrap: wrap;
    border-bottom: 1px solid black;
    border-right: 1px solid black;
`;
export const Message = styled.div`
    width: 194px;
    height: 129px;
    position: absolute;
    z-index: 1;
    top: 66px;
    left: 66px;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-wrap: wrap;
    background-color: ${new Colors().background};

    & span {
        font-size: 51px;
    }
`;

const NotFound = () => {
    return ( 
        <NotFoundContainer>
            <CubeLayer>
                <Message>
                    <h3>i got nothing <span role="img">🤷🏻‍♂️</span></h3>
                </Message>
                <GridSquare trail={true} color={colors.palette[Math.floor(Math.random() * (colors.palette.length - 1))]} />
                <GridSquare trail={true} color={colors.palette[Math.floor(Math.random() * (colors.palette.length - 1))]} />
                <GridSquare trail={true} color={colors.palette[Math.floor(Math.random() * (colors.palette.length - 1))]} />
                <GridSquare trail={true} color={colors.palette[Math.floor(Math.random() * (colors.palette.length - 1))]} />
                <GridSquare trail={true} color={colors.palette[Math.floor(Math.random() * (colors.palette.length - 1))]} />
                <GridSquare trail={true} color={colors.palette[Math.floor(Math.random() * (colors.palette.length - 1))]} />
                <GridSquare trail={true} color={colors.palette[Math.floor(Math.random() * (colors.palette.length - 1))]} />
                <GridSquare trail={true} color={colors.palette[Math.floor(Math.random() * (colors.palette.length - 1))]} />
                <GridSquare trail={true} color={colors.palette[Math.floor(Math.random() * (colors.palette.length - 1))]} />
                <GridSquare trail={true} color={colors.palette[Math.floor(Math.random() * (colors.palette.length - 1))]} />
                <GridSquare trail={true} color={colors.palette[Math.floor(Math.random() * (colors.palette.length - 1))]} />
                <GridSquare trail={true} color={colors.palette[Math.floor(Math.random() * (colors.palette.length - 1))]} />
                <GridSquare trail={true} color={colors.palette[Math.floor(Math.random() * (colors.palette.length - 1))]} />
                <GridSquare trail={true} color={colors.palette[Math.floor(Math.random() * (colors.palette.length - 1))]} />
                <GridSquare trail={true} color={colors.palette[Math.floor(Math.random() * (colors.palette.length - 1))]} />
                <GridSquare trail={true} color={colors.palette[Math.floor(Math.random() * (colors.palette.length - 1))]} />
                <GridSquare trail={true} color={colors.palette[Math.floor(Math.random() * (colors.palette.length - 1))]} />
                <GridSquare trail={true} color={colors.palette[Math.floor(Math.random() * (colors.palette.length - 1))]} />
                <GridSquare trail={true} color={colors.palette[Math.floor(Math.random() * (colors.palette.length - 1))]} />
                <GridSquare trail={true} color={colors.palette[Math.floor(Math.random() * (colors.palette.length - 1))]} />
            </CubeLayer>
        </NotFoundContainer>
     );
}
 
export default NotFound;