import styled from "styled-components/macro";
import Colors from "../../Utils/Colors";

const colors = new Colors();

const firstPosition = [131, 196];

export const BlogCard = styled.div`
  position: absolute;
  background: #efece5;
  height: 129px;
  width: 454px;
  left: 131px;
  top: ${({ index }) => firstPosition[1] + 195 * index}px;
  display: flex;

  @media (max-width: 1024px) {
    height: 164px;
    width: 439px;
    left: 111px;
    top: ${({ index }) => 166 + 220 * index}px;
  }

  @media (max-width: 600px) {
    height: 164px;
    width: 329px;
    left: 1px;
    top: ${({ index }) => 166 + 220 * index}px;
  }
`;

export const BlogImage = styled.div`
  height: 129px;
  width: 131px;
  position: relative;
  overflow: hidden;
  border-right: 1px solid black;

  & img {
    position: absolute;
    height: 166px;
    top: 50%; /* position the top  edge of the element at the middle of the parent */
    left: 50%; /* position the left edge of the element at the middle of the parent */
    transform: translate(-50%, -50%)
      scale(${({ loaded }) => (loaded ? 1 : 0.8)});
  }

  @media (max-width: 1024px) {
    height: 164px;
    width: 165px;

    & img {
      height: 126%;
    }
  }

  @media (max-width: 600px) {
    height: 164px;
    width: 111px;

    & img {
      height: 126%;
    }
  }
`;

export const BlogInfoContainer = styled.div`
  height: 100%;
  width: 67%;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  padding: 19px;
  text-align: left;

  & h2 {
    font-size: 16px;
  }

  @media (max-width: 1024px) {
    width: 274px;
    & h2 {
      font-size: 14px;
    }
  }
`;

export const BlogMeta = styled.div`
  height: 100%;
  width: 100%;
  display: flex;
  justify-content: flex-start;
  align-items: center;
`;
