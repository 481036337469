import styled from "styled-components/macro";
import Colors from "../../Utils/Colors";

const colors = new Colors();

export const Card = styled.div`
  position: absolute;
  background: #f7f6f3;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;

export const MiddleCard = styled(Card)`
  width: 454px;
  height: 584px;
  top: 131px;
  background-color: #fff8d7;

  & h3 {
    font-weight: 100;
    font-size: 27px;
    margin: 0;
    margin-bottom: 20px;
  }

  @media (max-width: 1140px) {
    left: 326px;
  }

  @media (max-width: 1024px) {
    left: 166px;
    width: 384px;
    height: 549px;
    top: 496px;

    & h3 {
      font-size: 24px;
    }
  }

  @media (max-width: 600px) {
    left: 1px;
    width: 329px;
    height: 604px;
    top: 716px;
  }
`;

export const GreetingCard = styled(Card)`
  width: 194px;
  height: 129px;
  top: 196px;
  left: 261px;
  background-color: #fad117;

  @media (max-width: 1530px) {
    left: 196px;
  }

  @media (max-width: 1270px) {
    left: 131px;
  }

  @media (max-width: 1024px) {
    left: 1px;
    top: 111px;
    width: 164px;
    height: 109px;
  }
`;

export const IntroCard = styled(Card)`
  width: 324px;
  height: 324px;
  top: 326px;
  left: 131px;
  padding: 25px;
  background-color: rgb(255, 237, 155);

  @media (max-width: 1530px) {
    left: 66px;
  }

  @media (max-width: 1270px) {
    left: 1px;
  }

  @media (max-width: 1024px) {
    left: 56px;
    width: 329px;
    height: 219px;
    top: 221px;
  }

  @media (max-width: 600px) {
    left: 1px;
    top: 441px;
  }
`;

export const ContactCard = styled(Card)`
  width: 259px;
  height: 129px;
  top: 456px;
  left: 1041px;
  padding: 25px;
  background-color: #ffe155;

  @media (max-width: 1530px) {
    left: 976px;
  }

  @media (max-width: 1390px) {
    left: 911px;
  }

  @media (max-width: 1270px) {
    left: 846px;
  }

  @media (max-width: 1140px) {
    left: 781px;
    width: 194px;
    height: 194px;
    top: 391px;
  }

  @media (max-width: 1024px) {
    left: 111px;
    width: 274px;
    height: 109px;
    top: 1101px;
  }

  @media (max-width: 600px) {
    left: 1px;
    top: 1376px;
  }
`;

export const PhotoCard = styled(Card)`
  width: 194px;
  height: 194px;
  top: 131px;
  left: 976px;

  @media (max-width: 1530px) {
    left: 911px;
  }

  @media (max-width: 1390px) {
    left: 846px;
  }

  @media (max-width: 1270px) {
    left: 781px;
  }

  @media (max-width: 1024px) {
    left: 441px;
    width: 220px;
    height: 219px;
    top: 221px;
  }

  @media (max-width: 600px) {
    left: 111px;
  }
`;

export const IconCard = styled(Card)`
  width: 64px;
  height: 64px;
  cursor: pointer;

  &:hover {
    background: ${({ color }) => colors.palette[color]};
  }
`;

export const MailCard = styled(IconCard)`
  top: 586px;
  left: 1041px;

  @media (max-width: 1530px) {
    left: 976px;
  }

  @media (max-width: 1390px) {
    left: 911px;
  }

  @media (max-width: 1270px) {
    left: 781px;
  }

  @media (max-width: 1024px) {
    left: 386px;
    top: 1156px;
    width: 54px;
    height: 54px;
  }

  @media (max-width: 600px) {
    left: 1px;
    top: 1486px;
  }
`;

export const LinkedInCard = styled(IconCard)`
  top: 586px;
  left: 1106px;

  @media (max-width: 1530px) {
    left: 1041px;
  }

  @media (max-width: 1390px) {
    left: 976px;
  }

  @media (max-width: 1270px) {
    left: 846px;
  }

  @media (max-width: 1024px) {
    left: 441px;
    top: 1156px;
    width: 54px;
    height: 54px;
  }

  @media (max-width: 600px) {
    left: 56px;
    top: 1486px;
  }
`;

export const InstagramCard = styled(IconCard)`
  top: 586px;
  left: 1171px;

  @media (max-width: 1530px) {
    left: 1106px;
  }

  @media (max-width: 1390px) {
    left: 1041px;
  }

  @media (max-width: 1270px) {
    left: 911px;
  }

  @media (max-width: 1140px) {
    top: 651px;
    left: 781px;
  }

  @media (max-width: 1024px) {
    left: 496px;
    top: 1156px;
    width: 54px;
    height: 54px;
  }

  @media (max-width: 600px) {
    left: 111px;
    top: 1486px;
  }
`;

export const ArtStationCard = styled(IconCard)`
  top: 586px;
  left: 1236px;

  @media (max-width: 1530px) {
    left: 1171px;
  }

  @media (max-width: 1390px) {
    left: 1106px;
  }

  @media (max-width: 1270px) {
    left: 976px;
  }

  @media (max-width: 1140px) {
    top: 651px;
    left: 846px;
  }

  @media (max-width: 1024px) {
    left: 551px;
    top: 1156px;
    width: 54px;
    height: 54px;
  }

  @media (max-width: 600px) {
    left: 166px;
    top: 1486px;
  }
`;

export const WorkItem = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-bottom: 15px;

  & h4 {
    font-size: 18px;
    margin: 3px;
  }

  & p {
    font-family: "MaisonNeue", Fallback, sans-serif;
    font-weight: 300;
    font-size: 17px;
  }

  @media (max-width: 1024px) {
    & p {
      font-size: 15px;
    }
  }
`;

export const SquareImageContainer = styled.div`
  width: 100%;
  height: 100%;
  background-color: ${({ color }) => color || colors.background};
  position: relative;
`;
