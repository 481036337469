import React from "react";
import { TitleItem } from "./GridItems.styles";
import logo from "../../assets/jc-logo.png";

const Title = () => {
  return (
    <TitleItem>
      {/* <img alt="juan-logo" src={logo} /> */}
      <h3>
        Juan Cuadra{" "}
        <span role="img" aria-label="icons">
          🚀
        </span>
      </h3>
    </TitleItem>
  );
};

export default Title;
