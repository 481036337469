import React from "react";
import styled from "styled-components";
import { useSpring, animated } from "react-spring";

import pat3 from "../assets/Group 3.png";

const PopUpContainer = styled(animated.div)`
  position: absolute;
  right: 0;
  bottom: 0;
  width: 389px;
  height: 194px;
  background-color: #4661ff;
  display: flex;
  flex-direction: column;
  padding: 20px 30px;
  text-align: left;
  color: white;
  border-width: 1px;
  border-style: solid;
  border-color: ${({ open }) => (open ? "black" : "transparent")};
  transform: translate(-5px, -5px);

  transition: border-color 0.4s ${({ open }) => (open ? 0 : 1)}s;

  & .greeting {
    position: relative;
  }

  & .greeting::after {
    content: "";
    position: absolute;
    bottom: -3px;
    left: 0;
    width: 100%;
    height: 2px;
    background-color: #fad117;
  }

  & .close {
    position: absolute;
    top: 9px;
    right: 16px;
    cursor: pointer;
  }

  & > * {
    margin: 5px 0px;
    opacity: ${({ open }) => (open ? 1 : 0)};
    font-size: 16px;

    transition: opacity 0.4s ${({ open }) => (open ? 0.9 : 0)}s;
  }

  @media (max-width: 1024px) {
    width: 100%;
    transform: translate(0px, 0px) !important;
    padding: 20px;

    & h3 {
      margin-top: 0px;
    }
  }
`;

const PopUpBackground = styled(animated.div)`
  position: absolute;
  right: 0;
  bottom: 0;
  z-index: 20;
  width: 389px;
  height: 194px;
  background: url("${pat3}") black center;
  opacity: ${({ open }) => (open ? 1 : 0)};

  transition: opacity 0.4s;

  @media (max-width: 1024px) {
    width: 100%;
    position: sticky;
  }
`;

const PopUp = ({ open, close }) => {
  const bg = useSpring({
    bottom: `${open ? 0 : -194}px`,
  });

  const pop = useSpring({
    transform: `translate(${open ? -5 : 0}px, ${open ? -5 : 0}px)`,
    delay: open ? 1000 : 0,
  });

  return (
    <PopUpBackground style={bg} open={open}>
      <PopUpContainer style={pop} open={open}>
        <h3>
          <span className="greeting">Hello</span>
          <span
            role="img"
            aria-label="hello"
            style={{ marginRight: "10px", marginLeft: "5px" }}
          >
            👋🏻
          </span>
          <span role="img" aria-label="robot">
            🤖
          </span>
        </h3>
        <p>
          I made this little website to show some of my life & some public work in the past
          few years.
        </p>
        <p>
          <span role="img" aria-label="above" style={{ marginRight: "10px" }}>
            👆🏻
          </span>
          <strong>Hover</strong> and <strong>click</strong> around the grid to
          get know me a bit better.
        </p>
        <span className="close" onClick={() => close()}>
          X
        </span>
      </PopUpContainer>
    </PopUpBackground>
  );
};

export default PopUp;
