import React, { Component } from "react";
import { Link } from "react-router-dom";

import Grid from "../Components/Grid";
import Title from "../Components/GridItems/Title";
import Button from "../Components/GridItems/Button";
import { setMenuButtons, setTitle, removeCategory } from "../Utils/GridControl";

import { getAllBlogs } from "../Utils/api";
import {
  BlogCard,
  BlogImage,
  BlogInfoContainer,
  BlogMeta,
} from "../Components/GridItems/BlogItems";
import Colors from "../Utils/Colors";

const colors = new Colors();

class BlogPage extends Component {
  constructor(props) {
    super(props);
    this.state = {
      gridReady: false,
      squareItems: [23, 13],
      loaded: false,
      posts: [],
    };
  }

  componentDidMount() {
    getAllBlogs().then((posts) =>
      this.setState({ posts: posts }, () => {
        // console.log(this.state.posts)
      })
    );
  }

  componentDidUpdate(prevProps, prevState) {
    if (!prevState.gridReady && this.state.gridReady) {
      if (window.innerWidth < 1025) return;
      setTitle();
      setMenuButtons(this.state.squareItems);
    }
  }

  render() {
    const { loaded, posts } = this.state;

    return (
      <Grid
        gridReady={(factor, squareItems) => {
          this.clearCategories();
          this.setState({ gridReady: true, squareItems: squareItems }, () => {
            if (window.innerWidth < 1025) return;
            setMenuButtons(
              this.state.squareItems,
              this.props.history.location.pathname
            );
            const event = new Event("update:square");
            document.dispatchEvent(event);
          });
        }}
      >
        {window.innerWidth < 1025 ? (
          <>
            <Link to="/">
              <Title />
            </Link>
            <Link to="/about">
              <Button
                leftHighlight
                height={1}
                width={2}
                title="About"
                color={colors.palette[1]}
                pathActive={"/about" === this.props.history.location.pathname}
                style={{ right: 0, top: "1px", left: "auto" }}
              />
            </Link>
            <Link to="/blog">
              <Button
                leftHighlight
                height={1}
                width={2}
                title="Blog"
                color={colors.palette[7]}
                pathActive={"/blog" === this.props.history.location.pathname}
                style={{ right: 0, top: "56px", left: "auto" }}
              />
            </Link>
          </>
        ) : null}
        {posts
          ? posts.map((post, i) => {
              return (
                <a
                  href={post.fields.articleLink}
                  target="_blank"
                  rel="noreferrer noopener"
                >
                  <BlogCard index={i}>
                    <BlogImage>
                      <img
                        alt="blog"
                        src={post.fields.mainImage.fields.file.url}
                      />
                    </BlogImage>
                    <BlogInfoContainer>
                      <h2>{post.fields.blogTitle}</h2>
                      <BlogMeta>
                        <p>
                          {post.fields.readTime} minutes - {post.fields.date}
                        </p>
                      </BlogMeta>
                    </BlogInfoContainer>
                  </BlogCard>
                </a>
              );
            })
          : null}
      </Grid>
    );
  }

  // Helpers

  clearCategories = () => {
    let aboutButton = document.getElementById(
      `square-${this.state.squareItems[0] - 2}`
    );
    let blogButton = document.getElementById(
      `square-${this.state.squareItems[0] * 2 - 2}`
    );

    blogButton.classList.add("blog-button");
    aboutButton.classList.add("about-button");

    removeCategory(aboutButton);
    removeCategory(blogButton);
  };
}

export default BlogPage;
